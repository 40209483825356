import { useState, useEffect } from "react";
import axiosInstance from "../axiosInstance";

const useProjectData = (
  projectId,
  selectedCategory = "In Process",
  debouncedSearchTerm,
  setSelectedCategory,
) => {
  const [project, setProject] = useState(null);
  const [statistics, setStatistics] = useState(null);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchProjectData = async () => {
    const res = await axiosInstance.getData(`/projects/${projectId}`);
    if (res.error) return;

    setProject(res.data.project);
    setStatistics(res.data.panelStatistics);
    setSelectedCategory(
      res?.data.panelStatistics?.processing === 0
        ? "Not Healthy"
        : "In Process",
    );
  };

  const fetchData = (ispage, isUpload, refreshProject) => {
    if (isLoading) return;
    setIsLoading(true);
    axiosInstance
      .getData(
        `/projects/images/${projectId}?page=${ispage || page}&limit=${
          selectedCategory === "Not Healthy" ? "9999" : "30"
        }${
          selectedCategory === "In Process"
            ? "&isHealthy=true&isInspected=false"
            : `&isHealthy=${selectedCategory === "Healthy"}&isInspected=true`
        }&searchTerm=${debouncedSearchTerm}`,
      )
      .then((res) => {
        if (isUpload) {
          setItems([...res?.data?.images]);
        } else {
          setItems((prevItems) => [...prevItems, ...res?.data?.images]);
        }
        if (refreshProject) {
          fetchProjectData();
        }
        setHasMore(res?.data?.moreImages);
      })
      .catch((err) => {
        setError(err);
      });

    setIsLoading(false);
  };
  useEffect(() => {
    fetchData(1, true);
  }, [projectId, selectedCategory, debouncedSearchTerm]);

  useEffect(() => {
    fetchProjectData();
  }, [projectId]);

  return {
    project,
    statistics,
    items,
    page,
    hasMore,
    isLoading,
    error,
    fetchProjectData,
    fetchData,
    setPage,
    setItems,
    setProject,
  };
};

export default useProjectData;
