import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import axiosInstance from "../../../axiosInstance";
import ImageInfiniteScroll from "../../../components/ImagesInfiniteScroll";
import ModalBox from "../../../components/ModalBox";
import { inputs } from "./Main";
import MapLocation from "../../../components/MapLocation";
import PieGraph from "../../../components/PieChart";
import Loading from "../../../components/Loading";
import Input from "../../../components/formComponents/Input";
import useDebounce from "../../../hooks/useDebounce";
import UploadForm from "../../../components/UploadForm";
import ModalLayout from "../../../components/ModalLayout";
import ProjectDetails from "../../../components/ProjectDetails";
import ProjectHeader from "../../../components/ProjectHeader";
import ProjectActions from "../../../components/ProjectActions";
import useGeneratePdf from "../../../hooks/useGeneratePdf";
import useProjectData from "../../../hooks/useProjectData";
import { updateWallet } from "../../../Redux/features/user/userSlice";

function View({ getImageColorCategory }) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [morePhoto, setMorePhoto] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [imageSelect, setImageSelect] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [image, setImage] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);

  const imageRefs = useRef([]);
  const project_id = parseInt(id);
  const categories = ["In Process", "Healthy", "Not Healthy"];
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { loadingPdf, handleGeneratePdf } = useGeneratePdf(id);
  const {
    project,
    statistics,
    items,
    hasMore,
    isLoading,
    error,
    fetchData,
    setItems,
    setPage,
    setProject,
  } = useProjectData(
    project_id,
    selectedCategory,
    debouncedSearchTerm,
    setSelectedCategory,
  );

  const getCategoryColor = (categoryName) => {
    if (categoryName === "In Process") {
      return "bg-orange-600";
    } else if (categoryName === "Healthy") {
      return "bg-green-600";
    } else if (categoryName === "Not Healthy") {
      return "bg-red-600";
    }
  };
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const watchedValues = watch();
  const credits = useSelector((state) => state.userReducer.wallet.credits);

  const handleEdit = () => {
    setOpenEdit((prev) => !prev);
    reset();
  };

  const handleFile = () => {
    setMorePhoto((prev) => !prev);
    reset();
  };

  const handleNextPage = () => {
    fetchData();
    setPage((prevPage) => prevPage + 1);
  };

  const updateData = async (data) => {
    setLoadingModal(true);
    data.megawatt = parseFloat(data.megawatt);
    const { megawatt, name, description } = data;
    const res = await axiosInstance.updateData("/projects/" + id, {
      megawatt,
      name,
      description,
    });
    if (res?.error) {
      setLoadingModal(false);
      return;
    }
    setProject(res?.data.project);
    toast.success("Project was updated successfully!");
    handleEdit();
    setLoadingModal(false);
  };

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCategory(category);
    setPage(2);
    setItems([]);
  };

  const handleImageClick = (image) => {
    if (image) {
      setImage(image);
    }
    setShowImage((prev) => !prev);
  };

  const handleImageSelected = (image) => {
    if (image) {
      setImageSelect(image);
      if (selectedCategory !== "Not Healthy") {
        setSelectedCategory("Not Healthy");
        setPage(1);
        setItems([]);
      }
    }
  };

  const handleImageSelect = (image) => {
    if (image.isInspected === true) {
      return;
    }
    const imageObj = {
      imageId: image.id,
      imageUrl: image.image,
      imageName: image.imageName,
      rgb: image.image.includes("_V"),
    };
    if (
      selectedImages.some(
        (selectedImage) => selectedImage.imageId === imageObj.imageId,
      )
    ) {
      setSelectedImages(
        selectedImages.filter(
          (selectedImage) => selectedImage.imageId !== imageObj.imageId,
        ),
      );
    } else {
      setSelectedImages([...selectedImages, imageObj]);
    }
  };

  const handleDeleteSelectedImages = async () => {
    const response = await axiosInstance.deleteData(`/projects/images/delete`, {
      data: { images: selectedImages },
    });
    if (response.status === "Ok!") {
      setOpenDelete(false);
      toast.success("Images have been successfully deleted");
      setSelectedImages([]);
      setItems([]);
      fetchData(1, false, true);
    } else {
      setOpenDelete(false);
    }
  };

  const handleCloseDelete = () => {
    reset();
    setOpen(!open);
  };

  const handleDelete = () => {
    setOpenDelete(!openDelete);
  };
  const handleInspection = async (data) => {
    setLoadingFile(true);
    const creditsPerUnit = data?.altitude === "LOW" ? 2 : 1;
    let creditsRequired;
    if (selectedImages.length > 0) {
      creditsRequired = selectedImages.length * creditsPerUnit;
    } else {
      creditsRequired = statistics?.processing * creditsPerUnit;
    }
    if (creditsRequired <= credits) {
      const res = await axiosInstance.postData("/projects/mail", {
        id: project_id,
        projectName: project.name,
        numberOfFiles:
          selectedImages.length > 0
            ? selectedImages.length
            : statistics?.processing,
        altitude: data?.altitude,
        drone: project?.drone,
        selectedImages: selectedImages.length > 0 ? selectedImages : null,
      });
      if (res.error) {
        setLoadingFile(false);
        return;
      }
      setProject((prevProject) => ({ ...prevProject, isinspected: true }));
      const creditsLeft = credits - creditsRequired;
      dispatch(updateWallet({ credits: creditsLeft }));
      toast.success("Processing has started.");
      setLoadingFile(false);

      return;
    }
    toast.error(
      "Processing can't be started because you don't have enough credits.",
    );
    setLoadingFile(false);
  };

  const handleFixes = async (isFixed, id) => {
    const response = await axiosInstance.updateData("/projects/images/" + id, {
      isFixed,
      isHealthy: true,
    });
    if (response?.error) {
      return;
    }
    setItems([]);
    fetchData(1);
    toast.success("Images changed was updated to isFixed!");
  };

  if (isLoading || project?.length === 0) {
    return <Loading fullscreen />;
  }
  return (
    <div className="w-full px-4 h-full">
      <ModalBox
        mode={"edit"}
        title={"Update Project"}
        open={openEdit}
        handleClose={handleEdit}
        handleSubmit={handleSubmit}
        onSubmit={updateData}
        inputs={inputs}
        register={register}
        control={control}
        errors={errors}
        loading={loadingModal}
        data={project}
      />
      <ModalLayout
        open={morePhoto}
        handleClose={handleFile}
        className="w-[400px]"
        innerClassName=" flex-col"
      >
        <h1 className={`text-3xl text-center `}>Upload more images</h1>
        <UploadForm
          project={project}
          fetchData={fetchData}
          setSelectedCategory={setSelectedCategory}
          handleClose={handleFile}
        />
      </ModalLayout>
      <ModalBox
        title={"Please choose the altitude model to use for this inspection?"}
        buttonTitle={"Submit"}
        formStyle="flex w-full flex-wrap items-center justify-center gap-y-5 gap-x-3"
        titleStyle="text-lg"
        open={open}
        modalClassName={"w-[350px] md:w-[450px]"}
        handleClose={handleCloseDelete}
        handleSubmit={handleSubmit}
        watch={watchedValues}
        selectedImages={selectedImages}
        onSubmit={(data) => {
          handleInspection(data);
          handleCloseDelete();
        }}
        inputs={[
          {
            type: "radio",
            className: "flex gap-y-3",
            options: [
              {
                text: "Low Altitude",
                id: "low",
                name: "altitude",
                altitude: "LOW",
                checked: true,
              },
              {
                text: "High Altitude",
                id: "high",
                name: "altitude",
                altitude: "HIGH",
                checked: false,
              },
            ],
          },
        ]}
        register={register}
        errors={errors}
        validation={true}
        imageCounter={statistics?.processing}
        credits={credits}
        loading={loadingModal}
        data={project}
      />
      <div className="w-full  flex flex-col md:flex-row ">
        <ProjectHeader project={project} />
        <ProjectActions
          project={project}
          loadingFile={loadingFile}
          loadingPdf={loadingPdf}
          statistics={statistics}
          handleCloseDelete={handleCloseDelete}
          handleEdit={handleEdit}
          setMorePhoto={setMorePhoto}
          handleGeneratePdf={handleGeneratePdf}
        />
      </div>
      <div className="flex flex-col-reverse justify-center md:flex-row w-full items-start ">
        <div className="w-full md:w-3/5">
          {project?.imagecounter > 0 ? (
            <div
              className={`justify-center items-center flex-col ${
                hasMore ? " min-h-screen" : " "
              }`}
            >
              <div className="flex md:flex-row flex-col justify-between items-end w-full py-4 md:pt-8">
                <div>
                  <PieGraph statistics={statistics} />
                </div>
                <select
                  value={selectedCategory || "In Process"}
                  onChange={handleCategoryChange}
                  className={`text-base font-medium h-full px-3 py-2.5 text-left focus:outline-none rounded-xl ${getCategoryColor(
                    selectedCategory || "In Process",
                  )}`}
                >
                  {categories?.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex justify-end w-full items-center gap-3 px-3 py-1 rounded-md ">
                {selectedImages.length > 0 && (
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-300"
                    onClick={() => handleDelete()}
                  >
                    Delete Selected
                  </button>
                )}
                <Input
                  id="search"
                  type="text"
                  placeholder="Search by name"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  className="w-full px-2 py-3 text-lg text-gray-400 rounded-lg border border-solid border-gray-700 focus:border-pink-600  focus:outline-none  bg-primary mb-2"
                />
              </div>
              <ImageInfiniteScroll
                fetchData={handleNextPage}
                items={items}
                setItems={setItems}
                imageRefs={imageRefs}
                hasMore={hasMore}
                error={error}
                getImageColorFilter={getImageColorCategory}
                handleImageClick={handleImageClick}
                handleFixes={handleFixes}
                setImage={setImage}
                showImage={showImage}
                image={image}
                imageSelect={imageSelect}
                selectedImages={selectedImages}
                handleImageSelect={handleImageSelect}
                openDelete={openDelete}
                handleDelete={handleDelete}
                handleDeleteSelectedImages={handleDeleteSelectedImages}
              />
            </div>
          ) : (
            !openEdit && (
              <UploadForm
                project={project}
                fetchData={fetchData}
                setSelectedCategory={setSelectedCategory}
              />
            )
          )}
        </div>
        <div className="flex md:sticky top-0 justify-end items-end flex-col w-full h-max md:w-2/5 md:pl-8 pt-6">
          <ProjectDetails project={project} />

          {project?.longitude && (
            <MapLocation
              id={id}
              longitude={project.longitude}
              imageRefs={imageRefs}
              latitude={project.latitude}
              handleImageClick={handleImageClick}
              setImageSelect={handleImageSelected}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default View;
//602
