import React from "react";

const ProjectDetails = ({ project }) => {
  return (
    <div className="pb-2 w-full">
      {project?.location && <p>Location: {project?.location}</p>}
      {project?.megawatt && <p>MegaWatt: {project?.megawatt}</p>}
      {project?.drone && (
        <p>
          Drone: {project?.drone?.make} - {project?.drone?.model}
        </p>
      )}
    </div>
  );
};

export default ProjectDetails;
