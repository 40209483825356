import { useState, useEffect } from "react";
import { CheckoutForm } from "../../components/Payments";
import ModalLayout from "../../components/ModalLayout";
import Button from "../../components/formComponents/Button";
import axiosInstance from "../../axiosInstance";
import { useSelector, useDispatch } from "react-redux";
import { updateWallet } from "../../Redux/features/user/userSlice";
import ReusableTable from "../../components/ReusableTable";

const Credits = () => {
  const credits = useSelector((state) => state.userReducer.wallet.credits);
  const [clickToPay, setClickToPay] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [offers, setOffers] = useState([]);
  const [transactionsHistory, setTransactionsHistory] = useState([]);
  const [paymenstHistory, setPaymentsHistory] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    fetchPackages();
    fetchTransactions();
    fetchCredits();
  }, []);
  const fetchCredits = async () => {
    const response = await axiosInstance.getData("/wallet/me");
    dispatch(updateWallet(response?.data));
  };
  const fetchPackages = async () => {
    const response = await axiosInstance.getData("/packages");
    setOffers(response);
  };

  const fetchTransactions = async () => {
    const response = await axiosInstance.getData("/payments/me");
    const transaction = await axiosInstance.getData("/transaction");

    setTransactionsHistory(transaction.data);
    setPaymentsHistory(response.data);
  };

  const handleOfferClick = (offer) => {
    setSelectedOffer(offer);
    setClickToPay(true);
  };

  const columns = [
    { header: "Credits", accessor: "credits" },
    {
      header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <span
          className={`py-3 capitalize px-4 ${
            value === "success" ? "text-green-500" : "text-red-500"
          }`}
        >
          {value}
        </span>
      ),
    },
    {
      header: "Date",
      accessor: "updatedAt",
      Cell: ({ value }) => new Date(value).toLocaleDateString("en-US"),
    },
  ];
  const transactionColumns = [
    { header: "Credits", accessor: "credits" },
    { header: "Project", accessor: "projectName" },
    { header: "Inspected Panels", accessor: "inspectedPanels" },
    {
      header: "Created At",
      accessor: "createdAt",
      Cell: ({ value }) => new Date(value).toLocaleDateString("en-US"), // Format the date
    },
  ];
  return (
    <div className="px-4 w-full lg:flex justify-center items-start">
      <ModalLayout
        open={clickToPay}
        handleClose={() => setClickToPay((prev) => !prev)}
        className=" w-full md:w-[70%] !bg-primary "
      >
        <CheckoutForm offer={selectedOffer} />{" "}
      </ModalLayout>
      <div className=" xl:w-[65%]">
        <div className="py-10 space-y-8">
          <div className="text-4xl font-bold text-white">Credits</div>
          <div className="bg-primary p-6 rounded-lg text-center border-[8px] border-gray-700">
            <div
              className="text-2xl font-semibold border-hidden text-white animate-pulse"
              style={{
                animationDuration: "1.5s",
              }}
            >
              Available Credits
            </div>

            <div
              className="text-6xl border-hidden font-bold text-[#FC620A] animate-pulse"
              style={{
                animationDuration: "1.5s",
                animationTimingFunction: "ease-in-out",
              }}
            >
              {credits}
            </div>

            <div className="text-sm text-gray-400">
              You can use your credits to make panel inspection.
            </div>
          </div>
        </div>

        <div className=" md:flex flex-grow gap-4">
          {offers?.map((offer) => (
            <div
              key={offer.id}
              className="bg-primary w-full my-4 p-6 rounded-lg shadow-lg text-center flex flex-col border border-gray-700 hover:border-[#FC620A] group transition-all"
            >
              <div className="text-3xl font-bold text-white group-hover:text-[#FC620A]">
                {offer.credits} Credits
              </div>
              <div className="text-xl text-gray-200 mt-2">${offer.price}</div>
              <p className="text-xl text-white mt-2">{offer.name}</p>
              <Button
                className="mt-4 px-4 py-2 rounded-md text-lg font-medium text-white"
                onClick={() => handleOfferClick(offer)}
                text={"Buy Now"}
              ></Button>
            </div>
          ))}
        </div>

        <div className="text-lg text-gray-300 space-y-3 pt-6">
          <div>
            <strong className="text-[#FC620A]">Buy More Credits: </strong>
            Buy credits and use them for future inspection!
          </div>
          <div>
            <strong className="text-[#FC620A]">Use Credits: </strong>
            Use your credits to unlock premium features or services.
          </div>
        </div>
      </div>
      <div className="space-y-8">
        <ReusableTable
          columns={transactionColumns}
          data={transactionsHistory}
        />
        <ReusableTable columns={columns} data={paymenstHistory} />
      </div>
    </div>
  );
};

export default Credits;
