import React, { useEffect, useState } from "react";
import axiosInstance from "../../../axiosInstance";
import Button from "../../../components/formComponents/Button";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ModalBox from "../../../components/ModalBox";
import Title from "../../../components/Title";
import moment from "moment";
import Delete from "../../../assets/icons/Delete";
import ConfirmModal from "../../../components/ConfirmModal";

const SkeletonCard = () => (
  <div className="animate-pulse">
    <div className="flex flex-col justify-between items-center rounded-xl bg-gray-800 h-40 w-full p-4">
      <div className="w-3/4 h-4 bg-gray-700 rounded"></div>
      <div className="w-full h-4 bg-gray-700 rounded mt-auto"></div>
    </div>
  </div>
);

export const inputs = [
  {
    name: "Name",
    id: "name",
    type: "text",
    placeholder: "Name",
    options: {
      required: "Name is required",
      minLength: {
        value: 3,
        message: "Minimum 3 characters required",
      },
    },
  },
  {
    name: "Description",
    id: "description",
    type: "textarea",
    placeholder: "Description",
    options: {
      minLength: {
        value: 5,
        message: "Minimum 5 characters required",
      },
    },
  },
  {
    name: "Megawatt",
    id: "megawatt",
    type: "number",
    steps: "0.1",
    min: 1,
    placeholder: "Megawatt",
    options: {
      min: {
        value: 1,
        message: "Minimum 1 megawatt required",
      },
    },
  },
];

const Main = () => {
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const navigate = useNavigate(); // Use the hook to navigate

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const [openDelete, setOpenDelete] = useState(false);

  const handleCloseDelete = (id) => {
    if (id) {
      setId(id);
    }
    reset();
    setOpenDelete(!openDelete);
  };

  const deleteProject = () => {
    axiosInstance
      .deleteData(`projects/${id}`)
      .then((res) => {
        toast.success("Project deleted successfully");
        setUpdate(!update);
      })
      .catch((err) => {
        toast.error("Error deleting project");
      });

    handleCloseDelete();
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      data.megawatt = parseFloat(data.megawatt);
      await postData(data);
    } catch (err) {
      toast.error(err.message);
    }
    setLoading(false);
  };

  const postData = async (data) => {
    const res = await axiosInstance.postData("/projects", data);
    if (res.error) {
      return;
    }
    handleClose();
    toast.success("Project was added successfully!");
    setFilteredProjects((prevProjects) => [res.data.project, ...prevProjects]);
    navigate(`/admin/project/${res.data.project.id}`); // Use navigate to redirect
  };

  const sortProjects = () => {
    const sorted = [...filteredProjects].sort((a, b) => {
      const dateA = new Date(a.createdat).getTime();
      const dateB = new Date(b.createdat).getTime();
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });

    setFilteredProjects(sorted);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await axiosInstance.getData("/projects/me");
      setFilteredProjects(res.data);
      setLoading(false);
      if (res.error) {
        setLoading(false);
      }
    };
    fetchData();
  }, [update]);

  return (
    <div className=" text-black flex justify-start flex-col w-full h-full overflow-hidden">
      <Title
        title={"Projects"}
        className={"text-xl flex relative items-start pb-8"}
      />
      <div className="mb-10 w-full px-8 flex justify-between items-center flex-wrap gap-4">
        <Button
          type="button"
          text="New Project"
          className="rounded-md px-8 "
          onClick={handleOpen}
        />
        <ModalBox
          mode={"create"}
          title={"Create Project"}
          open={open}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          inputs={inputs}
          register={register}
          control={control}
          errors={errors}
          loading={loading}
        />
        <ConfirmModal
          title={"Are you sure you want to delete project?"}
          handleClose={handleCloseDelete}
          open={openDelete}
          onSubmit={deleteProject}
          loading={loading}
          buttonText={"Delete"}
        />
        <div className="flex items-center gap-4">
          <Button
            type="button"
            text={`Sort by Date ${sortOrder === "asc" ? "↑" : "↓"}`}
            className="rounded-md px-8 flex items-center gap-2"
            onClick={sortProjects}
          ></Button>
        </div>
      </div>
      <div className="grid sm:grid-cols-2  lg:grid-cols-3 xl:grid-cols-5 gap-4 pb-4 px-8">
        {loading
          ? Array(15)
              .fill()
              .map((_, index) => <SkeletonCard key={index} />)
          : filteredProjects?.map((data) => {
              return (
                <div className="relative group/card">
                  <button
                    className="absolute top-2 right-2  justify-between hidden group-hover/card:block items-center gap-3 px-2 py-1 rounded-md group cursor-pointer text-xl"
                    onClick={() => handleCloseDelete(data?.id)}
                  >
                    <Delete
                      width="15px"
                      height="15px"
                      fill="#ffff"
                      className={"group-hover:fill-red-500 "}
                    />
                  </button>
                  <Link
                    to={`/admin/project/${data?.id}`}
                    key={data?.id}
                    style={{ textDecoration: "none" }}
                  >
                    <tr
                      className="flex flex-col justify-between items-center rounded-xl text-white text-ellipsis overflow-hidden text-center h-40 w-full p-4"
                      style={{
                        border: "2px solid #0000",
                        borderBottom: "none",
                        background:
                          "linear-gradient(#222239,#352B53) padding-box, linear-gradient(to right, #FF6B00 0%, #DD0077 55%, #7000FF 100%) border-box",
                      }}
                    >
                      <td>
                        <h1 className="text-base pt-2 max-w-[96%]">
                          {data?.name}
                        </h1>
                      </td>

                      <td className="text-white flex justify-start h-40 w-full items-end">
                        <h4 className="text-gray-300 text-xs  bottom-0 right-0">
                          Created at:{" "}
                          {moment(data.createdat).format("DD/MM/YYYY")}
                          <br />
                          Last update:{" "}
                          {moment(data.updatedat).format("DD/MM/YYYY")}
                        </h4>
                      </td>
                    </tr>
                  </Link>
                </div>
              );
            })}
      </div>
      {!loading && filteredProjects?.length === 0 && (
        <div className="text-center text-gray-600 mt-8">
          <p>No projects found.</p>
          {/* <Button
            type="button"
            text="Show All Projects"
            className="mt-4 bg-blue-500 text-white rounded-md px-4 py-2"
            onClick={resetFilters}
          /> */}
        </div>
      )}
    </div>
  );
};

export default Main;
